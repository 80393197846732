import React from 'react';
import { useTriggers } from '../../hooks/navigation';

export default function ThemeSwitcher() {
  const { handleThemeSwicherBtnClick } = useTriggers();
  return (
    <div
      className="w-layout-hflex theme-switcher-container greyish-bg-in-darkmode whiten-border-in-dark-mode"
      onClick={handleThemeSwicherBtnClick}
      prop-events-value-onclick="handleThemeSwicherBtnClick"
    >
      <img
        alt=""
        className="theme-switcher-icon"
        loading="lazy"
        src="/images/moon-svgrepo-com.svg"
      />
      <img
        alt=""
        className="theme-switcher-icon light-mode"
        loading="lazy"
        src="/images/sun.svg"
      />
    </div>
  );
}
