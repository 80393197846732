import Sidebar from '../../components/Sidebar/Sidebar';
import Logo from '../header/Logo';
import React from 'react';
import { useTriggers } from '../../hooks/navigation';
import ThemeSwitcher from './theme-switcher';

export default function MobileSidebar({ show }) {
  const { handleMobileHeaderMenuToggleClick } = useTriggers();
  return (
    <div
      data-collapsed="true"
      className={`w-layout-hflex site-left-sidebar-block black-bg-dark-mode`}
      style={{
        width: '292px',
        height: '100%',
        display: show ? 'flex' : 'none',
      }}
    >
      <div className="w-layout-hflex flex-block-92">
        <div
          behave-as-btn="1"
          prop-events-names="onClick"
          prop-events-value-onclick="handleHeaderMenuToggleClick"
          tabIndex="99"
          data-w-id="8f2ed2b8-d3dd-1bce-014c-90f64c8654d5"
          className="menu-toggler-v2 greyish-bg-in-darkmode"
          onClick={handleMobileHeaderMenuToggleClick}
        >
          <img
            src="/images/align-justify.svg"
            loading="lazy"
            alt="VIew and Hide Recent Messages Tab"
            width="24"
            className="menu-toggler-icon whiten-in-dark-mode"
          />
        </div>
        <div className="logo-container" style={{ display: 'flex' }}>
          <Logo />
        </div>
      </div>
      <Sidebar />
      <ThemeSwitcher />
    </div>
  );
}
