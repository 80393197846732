import { useState, useEffect, useRef } from 'react';
import { marked } from 'marked';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import { toast } from 'react-toastify';
import useGuidedSuggestedPrompts from './useGuidedSuggestedPrompts';
import useFreeTrial from '../freetrial/useFreeTrial';
import useRecentMessages from '../api/messages/useRecentMessages';

let chatSocket = null;
let stopGenerationSocket = null;
let LATEST_GENERATION_ID = '';
let responseInProgress = '';

export default function useChatRoom() {
  const chat = useSelector((state) => state.chat.data);
  const pageContent = useSelector((state) => state.chat.pageContent);
  const globalGenerating = useSelector((state) => state.chat.generating);
  const globalStreaming = useSelector((state) => state.chat.streaming);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const allMessages = useSelector((state) => state.chat.messages);
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const [aiResponseInProgress, setAiResponseInProgress] = useState('');
  const [streaming, setStreaming] = useState(false);
  const [generating, setGenerating] = useState(false);
  const { loadGuidedPrompts } = useGuidedSuggestedPrompts();
  const { reloadRecentMessages } = useRecentMessages();

  const { reloadFreeTrialCount } = useFreeTrial(true);

  const chatPayload = {
    flow: {
      name: mentors?.mentor?.unique_id,
      tenant: auth?.tenant?.key,
      username: auth?.user?.user_nicename,
      pathway: mentors?.mentor?.name,
    },
    session_id: sessionId,
    token: localStorage.getItem('axd_token'),
  };

  const autoScrollIfNecessary = () => {
    const msgElements = document.getElementsByClassName('upper-area');
    if (msgElements?.length > 0) {
      const last_msg = msgElements[0];
      if (last_msg) {
        last_msg.scrollTop = last_msg.scrollHeight;
      }
    }
  };

  const _handleAddUserPromptToMessages = (hide = false, content = null) => {
    const proActiveSession = localStorage.getItem('proActiveSession');
    if (proActiveSession) {
      hide = true;
    }
    const message = {
      type: 'human',
      content: content || chat?.prompt,
      hide: hide,
    };
    const messages = [...(allMessages ?? []), message];
    dispatch(chatActions.messagesUpdated(messages));
  };

  const _handleIncomingMessage = (event) => {
    let response = JSON.parse(event.data);

    if (response?.detail === 'Connected.') {
      _handleSendTextQuery();
    }
    autoScrollIfNecessary();
    if (response?.generation_id) {
      LATEST_GENERATION_ID = response?.generation_id;
    }

    if (response?.data) {
      setStreaming(true);
      responseInProgress += response?.data;
      setAiResponseInProgress(responseInProgress);
      setGenerating(false);
    }

    if (response?.eos) {
      responseInProgress = '';
      _endConnection();
      loadGuidedPrompts();
    }

    if (response?.error) {
      toast.error(response.error);
      setGenerating(false);
      setStreaming(false);
      _endConnection();
    }
  };

  const _handleResetPrompt = () => {
    dispatch(chatActions.chatUpdated({ ...chat, prompt: '' }));
  };

  const _initiateConnection = () => {
    chatSocket?.send(JSON.stringify(chatPayload));
  };

  const _handleSendTextQuery = () => {
    const proActiveSession = localStorage.getItem('proActiveSession');
    const payload = { ...chatPayload, prompt: chat.prompt };
    if (pageContent) {
      payload.page_content = pageContent;
    }
    chatSocket?.send(JSON.stringify(payload));
    if (proActiveSession) {
      localStorage.removeItem('proActiveSession');
    }
    _resetPageContent();
  };

  const _endConnection = () => {
    if (chatSocket?.readyState === WebSocket.OPEN) {
      chatSocket?.close();
    }
    setGenerating(false);
    setStreaming(false);
    localStorage.removeItem('proActiveSession');
  };

  const handleStopRespondingBtnClick = () => {
    _handleOpenStopGenerationConnection();
  };

  useEffect(() => {
    if (allMessages?.length > 0) {
      const messages = [...allMessages];
      if (messages?.length > 0) {
        const lastMsg = messages.pop();
        if (lastMsg.type === 'human') {
          dispatch(chatActions.chatUpdated({ ...chat, prompt: '' }));
        }
      }
    }
  }, [allMessages, dispatch]);

  const effectRan = useRef(false);

  useEffect(() => {
    if (
      allMessages?.length > 1 &&
      globalGenerating === false &&
      globalStreaming === false &&
      !effectRan.current
    ) {
      effectRan.current = true;
      reloadFreeTrialCount();
      reloadRecentMessages();
    }

    return () => {
      effectRan.current = false;
    };
  }, [allMessages, globalStreaming, globalGenerating]);

  useEffect(() => {
    autoScrollIfNecessary();
  }, [allMessages]);

  useEffect(() => {
    let mounted = true;
    if (aiResponseInProgress && mounted) {
      const content = marked.parse(aiResponseInProgress);
      let allMsg = [...(allMessages ?? [])];
      let lastMsg = allMsg.pop();

      // If yes then the last message in display is a response in progress
      if (lastMsg?.generation_id === LATEST_GENERATION_ID) {
        const updatedMsg = {
          ...lastMsg,
          content,
          streaming,
          rawContent: aiResponseInProgress,
        };
        allMsg.push(updatedMsg);
      } else {
        // Means the last message is not a response in progress. Put it back and add the new one
        allMsg.push(lastMsg);
        allMsg.push({
          type: 'ai',
          content: content,
          generation_id: LATEST_GENERATION_ID,
          streaming: streaming,
          rawContent: aiResponseInProgress,
        });
      }

      dispatch(chatActions.messagesUpdated(allMsg));
    }

    return () => {
      mounted = true;
    };
  }, [chat?.generating, aiResponseInProgress, streaming, dispatch]);

  const _initiateServerStopGeneration = () => {
    stopGenerationSocket.send(
      JSON.stringify({
        generation_id: LATEST_GENERATION_ID,
        name: mentors?.mentor?.name,
        tenant: auth?.tenant?.key,
        username: auth?.user?.user_nicename,
        token: localStorage.getItem('axd_token'),
      })
    );
  };

  const handleStopGenerationSocketMessage = (event) => {
    let response = JSON.parse(event.data);
    if (response?.detail === 'Stopped') {
      responseInProgress = '';
      setStreaming(false);
      stopGenerationSocket.close();
      _endConnection();
      loadGuidedPrompts();
    }
  };

  const _handleOpenStopGenerationConnection = () => {
    stopGenerationSocket = new WebSocket(
      `${process.env.REACT_APP_BASE_WS_URL}/ws/langflow-stop-generation/`
    );
    stopGenerationSocket.onopen = _initiateServerStopGeneration;
    stopGenerationSocket.onmessage = handleStopGenerationSocketMessage;
  };

  const _handleOpenWebsocketConnection = () => {
    setGenerating(true);
    chatSocket = new WebSocket(
      `${process.env.REACT_APP_BASE_WS_URL}/ws/langflow/`
    );
    chatSocket.onerror = () => {
      toast.error('Unable to connect to server');
      _endConnection();
    };
    chatSocket.onmessage = _handleIncomingMessage;
    chatSocket.onopen = () => {
      setTimeout(_initiateConnection, 1000);
    };
  };

  const handleUserPromptFormSubmit = (event) => {
    event?.preventDefault();
    if (chat?.prompt) {
      _handleAddUserPromptToMessages();
      _handleOpenWebsocketConnection();
    }
  };

  const _resetPageContent = () => {
    dispatch(chatActions.pageContentUpdate(null));
  };

  const handlePromptChange = (event) => {
    const prompt = event.target.value;
    dispatch(chatActions.chatUpdated({ ...chat, prompt: prompt }));
  };

  return {
    generating,
    handleUserPromptFormSubmit,
    handlePromptChange,
    streaming,
    handleStopRespondingBtnClick,
  };
}
