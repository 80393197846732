import { createSlice } from '@reduxjs/toolkit';

export const chatInitialState = {
  data: {
    sessionId: null,
    messages: null,
    prompt: '',
    sequence: 1,
  },
  pinMessages: null,
  sessionId: null,
  recentMessages: null,
  moreRecentMessages: [],
  guidedPrompts: null,
  generating: false,
  streaming: false,
  messages: null,
  pageContent: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: chatInitialState,
  reducers: {
    reset: () => chatInitialState,
    chatUpdated: (state, action) => {
      state.data = action.payload;
    },
    pinMessagesUpdated: (state, action) => {
      state.pinMessages = action.payload;
    },
    recentMessagesUpdated: (state, action) => {
      state.recentMessages = action.payload;
    },
    moreRecentMessagesUpdated: (state, action) => {
      state.moreRecentMessages = action.payload;
    },
    guidedPromptsUpdated: (state, action) => {
      state.guidedPrompts = action.payload;
    },
    generatingUpdated: (state, action) => {
      state.generating = action.payload;
    },
    streamingUpdated: (state, action) => {
      state.streaming = action.payload;
    },
    messagesUpdated: (state, action) => {
      state.messages = action.payload;
    },
    sessionUpdated: (state, action) => {
      state.sessionId = action.payload;
    },
    pageContentUpdate: (state, action) => {
      state.pageContent = action.payload;
    },
  },
});

export const chatActions = chatSlice.actions;
export default chatSlice.reducer;
