import React, { useContext, useEffect, useRef, useState } from 'react';
import Home from './home';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../lib/redux/auth/slice';
import useSetCurrentMentor from '../../hooks/api/mentors/useSetCurrentMentor';
import PageLoader from '../loader/page-loader';
import { ToastContainer, Slide } from 'react-toastify';
import useIframeEvents from '../../hooks/iframe/useIframeEvents';
import Creatementormodalbox from '../../components/Creatementormodalbox/Creatementormodalbox';
import Editmentormodalbox from '../../components/Editmentormodalbox/Editmentormodalbox';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import useAnonymousMentor from '../../hooks/iframe/useAnonymousMentor';
import useGuidedSuggestedPrompts from '../../hooks/chat-room/useGuidedSuggestedPrompts';
import {
  AppDataContext,
  useDashboard,
  useUserTimeTracking,
} from '@iblai/ibl-web-react-common';

export default function HomeWrapper() {
  const { appData, setAppData } = useContext(AppDataContext);
  const anonymous = useAnonymousMode();
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  useUserTimeTracking();

  const mentors = useSelector((state) => state.mentors);
  const [startMentorSetup, setStartMentorSetup] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSetCurrentMentor(startMentorSetup);
  const anonymousMentor = useAnonymousMentor();
  const { handleIframeMessage, notifyParentOnReady } = useIframeEvents();
  const { modals } = useSelector((state) => state.navigation.data);
  const { loadGuidedPrompts } = useGuidedSuggestedPrompts();

  const pathName = window.location.pathname;

  useEffect(() => {
    if (!anonymous && auth.user && auth.tenant) {
      setStartMentorSetup(true);
    }
  }, [auth]);

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    notifyParentOnReady();
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    if (sessionId) {
      loadGuidedPrompts();
    }
  }, [sessionId]);

  useEffect(() => {
    const authData = { ...localStorage };
    const authState = {};
    if (authData?.userData) {
      authState['tenants'] = JSON.parse(authData.tenants);
      authState['tenant'] = JSON.parse(authData.current_tenant);
      authState['user'] = JSON.parse(authData.userData);

      dispatch(authActions.authUpdated(authState));
    }
  }, []);

  return (
    <>
      {(pathName.includes('/share/chat') ||
        ((auth?.user || anonymous) && mentors?.mentor)) && <Home />}{' '}
      {(loading || anonymousMentor?.loading) && <PageLoader />}
      {modals?.['mentor-creation-modal'] && !mentors?.mentor && (
        <Creatementormodalbox />
      )}
      {modals?.['edit-mentor-modal'] && mentors?.mentors?.count === 0 && (
        <Editmentormodalbox />
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
}
