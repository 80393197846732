import { TrialTopHeader } from '@iblai/ibl-web-react-common';
import useFreeTrial from '../../hooks/freetrial/useFreeTrial';
import { useSelector } from 'react-redux';
import { mentorIsIframe } from '../../utils/shared';

export default function FreeTrialBanner() {
  const auth = useSelector((state) => state.auth);
  const { handleUpgradeClick, onFreeTrial } = useFreeTrial(false);

  const tooltip = `You can chat with the mentors but upgrade in order to create your own. ${auth?.freeTrialUsage?.count} questions left.`;
  const pillDisplay = `Upgrade`;
  const description = `You are on a free trial. ${auth?.freeTrialUsage?.count} questions left`;
  return (
    <>
      {onFreeTrial && auth?.freeTrialUsage?.count && !mentorIsIframe() && (
        <TrialTopHeader
          handleClick={handleUpgradeClick}
          tooltipText={tooltip}
          pillDisplayText={pillDisplay}
          descriptionText={description}
          fixedClassNamesToMove=".header, .main-content, .site-left-sidebar-block"
        />
      )}
    </>
  );
}
