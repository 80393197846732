import React from 'react';
import { useSelector } from 'react-redux';
import { useHeader } from '../../hooks/header/useHeader';
import useIframeLogo from '../../hooks/iframe/useIframeLogo';

export default function Logo() {
  const darkMode = useSelector((state) => state.navigation.darkMode);
  const mentors = useSelector((state) => state.mentors);
  const { USE_SPECIAL_IFRAME_LOGO, logoSize } = useHeader();
  const iframeLogo = useIframeLogo();

  return (
    <div className="w-layout-hflex" style={{ alignItems: 'center' }}>
      <img
        src={`${USE_SPECIAL_IFRAME_LOGO ? iframeLogo : darkMode ? '/images/logo-dark.gif' : '/images/logo.gif'}`}
        loading="lazy"
        width="Auto"
        sizes="100vw"
        alt="IBL Mentor Pro logo"
        className="image-58"
        style={{
          width: `${logoSize}px`,
          borderRadius: USE_SPECIAL_IFRAME_LOGO ? '50%' : '0',
        }}
      />
      {USE_SPECIAL_IFRAME_LOGO && (
        <div
          className="logo-title"
          style={{ marginLeft: '10px', fontWeight: 'bold' }}
        >
          {mentors?.mentor?.name}
        </div>
      )}
    </div>
  );
}
